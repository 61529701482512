import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_INPUT_GENERICO } from "../../../utils/const/estilos-const";
import "../../../style/style-cargando.css";
import cargandoCard from "../../../components/cargando-card";
import Select from "react-select";
import { obtenerListaCorreos } from "../../../api/correo";
import { crearAlertaChecklist, modificarAlertaChecklist } from "../../../api/alerta-checklist";

export default function AlertaChecklist() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [opcionRespuesta, setOpcionRespuesta] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [isSwitchOnEnviarCorreo, setIsSwitchOnEnviarCorreo] = useState(false);
  const [isSwitchOnTomarFoto, setIsSwitchOnTomarFoto] = useState(false);
  const [isSwitchOnObservacion, setIsSwitchOnObservacion] = useState(false);
  const [listaCorreos, setListaCorreos] = useState([]);
  const [correosSelected, setCorreosSelected] = useState(null);

  useEffect(() => {
    // console.log("entro a Correos");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      // console.log("data");
      // console.log(data);
      if (data !== undefined && data !== null) {
        setOpcionRespuesta(data.params);
        setTitulo(data.titulo);
      }
      const listCorreos = await obtenerListaCorreos(true);
      setListaCorreos(listCorreos);
      // console.log("listCorreos");
      // console.log(listCorreos);
      if (data.params.idCorreo !== null) {
        // console.log('existe correo');
        // console.log('idCorreo -> ' + data.params.idCorreo);
        const correo = listCorreos.find(e => e.value === data.params.idCorreo);
        // console.log("correo");
        // console.log(correo);
        if (correo !== undefined) {
          setCorreosSelected(correo);
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setOpcionRespuesta({ ...opcionRespuesta, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleSaveClick = async () => {
    // console.log('handleSaveClick');
    // lógica para guardar los cambios
    console.log('opcionRespuesta');
    console.log(opcionRespuesta);
    console.log('correosSelected');
    console.log(correosSelected);
    let objEnviar = {};
    objEnviar.id = opcionRespuesta.id;
    objEnviar.descripcion = opcionRespuesta.descripcion;
    objEnviar.idCorreo = correosSelected !== null ? correosSelected.value : null;
    objEnviar.nota = opcionRespuesta.nota;
    if (opcionRespuesta.id <= 0) {
      // console.log('crear correo');
      let creado = await crearAlertaChecklist(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (creado === true) {
        alert('Se creo correctamente');
        handleCancelClick();
      }
      if (creado === false) {
        alert('No se pudo crear');
      }
    }
    if (opcionRespuesta.id > 0) {
      // console.log('actualizar correo');
      let modificado = await modificarAlertaChecklist(objEnviar);
      // console.log('modificado');
      // console.log(modificado);
      if (modificado === true) {
        alert('Se modifico correctamente');
        handleCancelClick();
      }
      if (modificado === false) {
        alert('No se pudo modificar');
      }
    }
  };

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (cargandoPage === false && opcionRespuesta === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div className="min-h-screen bg-gray-100 flex flex-col  py-12 sm:px-6 lg:px-8">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              {titulo}
            </h2>
            <div className="mt-6 bg-white py-6 px-4 shadow-md rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <div className="mt-1">
                    <label>DESCRIPCION</label>
                    <input
                      id="descripcion"
                      name="descripcion"
                      type="text"
                      value={opcionRespuesta.descripcion}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      required
                      maxLength={127}
                    />
                  </div>
                </div>

                <div>
                  <label>CORREO</label>
                  <Select
                    options={listaCorreos}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                    value={correosSelected}
                    onChange={(e) => {
                      setCorreosSelected(e);
                    }}
                    placeholder={'SELECCIONE CORREOS'}
                  />
                </div>

                <div className="mt-1">
                  <label>NOTA</label>
                  <textarea
                    id="nota"
                    name="nota"
                    type="text"
                    value={opcionRespuesta.nota}
                    onChange={handleInputChange}
                    autoComplete="given-name"
                    className={ESTILO_INPUT_GENERICO}
                    required
                    maxLength={1023}
                  />
                </div>

                <div className="flex justify-end">
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_CANCELAR}
                      onClick={handleCancelClick}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_ACEPTAR}
                      onClick={handleSaveClick}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      console.log('ModificarCorreos render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
