import moment from "moment/moment";
import { SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";
import { TRUE } from "../utils/const/bool-const";

export async function obtenerListaTipoOpcionesRespuesta(combo = false) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/opciones-respuesta/obtener-lista-opciones-respuesta/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    body[i].enviarCorreoLabel = element.enviarCorreo === TRUE ? "SI" : "NO";
                    body[i].tomarFotoLabel = element.tomarFoto === TRUE ? "SI" : "NO";
                    body[i].notaObservacionLabel = element.notaObservacion === TRUE ? "SI" : "NO";
                    body[i].fechaProceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
                    body[i].pedirFechaLabel = element.pedirFecha === TRUE ? "SI" : "NO";
                    rows.push(body[i]);
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerTipoChecklist err");
        console.log(err);
        return [];
    }
}

export async function crearOpcionesRespuesta(obj) {
    try {
        let creado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/opciones-respuesta/guardar-opcion-respuesta/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            creado = true;
        }
        return creado;
    } catch (err) {
        console.log("crearOpcionesRespuesta err");
        console.log(err);
        return false;
    }
}

export async function modificarOpcionRespuesta(obj) {
    try {
        let modificado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/opciones-respuesta/modificar-opciones-respuesta/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            modificado = true;
        }
        return modificado;
    } catch (err) {
        console.log("modificarOpcionRespuesta err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarOpcionRespuesta(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/opciones-respuesta/deshabilitar-opciones-respuesta/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarOpcionRespuesta err");
        console.log(err);
        return false;
    }
}
