import moment from "moment/moment";
import { SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerListaAlertaChecklist(combo = false) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/alerta-checklist/obtener-lista-alerta-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                for (let i = 0; i < body.length; i++) {
                    body[i].fechaProceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm')
                    rows.push(body[i]);
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaArea err");
        console.log(err);
        return [];
    }
}

export async function crearAlertaChecklist(obj) {
    try {
        let creado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/alerta-checklist/guardar-alerta-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            creado = true;
        }
        return creado;
    } catch (err) {
        console.log("crearArea err");
        console.log(err);
        return false;
    }
}

export async function modificarAlertaChecklist(obj) {
    try {
        let modificado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/alerta-checklist/modificar-alerta-checklist/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            modificado = true;
        }
        return modificado;
    } catch (err) {
        console.log("modificarArea err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarAlertaChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/alerta-checklist/deshabilitar-alerta-checklist/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarArea err");
        console.log(err);
        return false;
    }
}
