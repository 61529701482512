export const SELECT_FORMULARIO_CHECKLIST = [
    {
        value: 11,
        label: 'SELECCIONAR UNIDAD',
    },
    {
        value: 12,
        label: 'INPUT ODOMETRO ACTUAL',
    },
    {
        value: 13,
        label: 'INPUT ODOMETRO PROXIMO',
    },
    {
        value: 14,
        label: 'FECHA ACTUAL CHECKLIST',
    },
    {
        value: 15,
        label: 'FECHA PROXIMO CHECKLIST',
    },
    {
        value: 16,
        label: 'SELECCIONAR CONDUCTOR',
    },
    {
        value: 17,
        label: 'SELECCIONAR AREA',
    },
    {
        value: 18,
        label: 'SELECCIONAR TIPO CHECKLIST',
    },
    {
        value: 19,
        label: 'SELECCIONAR TIPO VEHICULO',
    },
    {
        value: 20,
        label: 'SELECCIONAR MARCA VEHICULO',
    },
    {
        value: 21,
        label: 'SELECCIONAR TIPO CARROCERIA',
    },
    {
        value: 22,
        label: 'SELECCIONAR TIPO COMBUSTIBLE',
    },
    {
        value: 24,
        label: 'ULTIMO ODOMETRO REGISTRADO',
    },
    {
        value: 25,
        label: 'ULTIMA TEMPERATURA REGISTRADA',
    },
];